(function($) {
  objectFitImages('.of-cover');

  {
    let html = document.querySelectorAll('html').item(0);
    let btn = document.querySelectorAll('.btn-hamburger').item(0);

    btn.addEventListener('click', (event) => {
      event.preventDefault();
      html.classList.toggle('js-drawer-open');
    }, false);
  }

  {
    let $el = $('#js-scroll-to-top');

    $(window).scroll(function() {
      if ($(this).scrollTop() < 10) {
        $el.addClass('is-hide');
      } else {
        $el.removeClass('is-hide');
      }
    }).trigger('scroll');

    $el.on('click', function() {
      $('html, body').animate({scrollTop: 0}, 400);

      return false;
    });
  }

  if ($('body').is('.home')) {
    $('.slider').bxSlider({
      auto: true,
      controls: false,
      mode: 'fade',
      pager: false,
      pause: 6000
    });

    new Swiper('.swiper-container', {
      spaceBetween: 20,
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });
  }

  if ($('body').is('.company')) {
    let $parent = $('#js-parent-category');
    let $children = $('.js-child-categories');
    let isInit = true;

    $parent.change(function() {
      $children.attr('disabled', true).hide();

      if (this.value == '') {
        $children.first().show().val('');
      } else {
        let $child = $children.filter('[data-parent-term-id="' + this.value + '"]');

        $child.attr('disabled', false).show();
        if (!isInit) $child.val('');
      }

      isInit = false;
    }).trigger('change');
  }
})(jQuery);
